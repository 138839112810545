<template>
  <div class="common-table" style="height: 100%">
    <!--大表格，：data后接数据-->
    <el-table
      v-loading="config.loading"
      :data="dataList"
      highlight-current-row
      border
      height="90%"
      stripe
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column label="序号" prop="id" width="80"></el-table-column>
      <el-table-column label="材料名称" prop="cailiao_cailiaoguige" width="160" sortable> </el-table-column>
      <el-table-column label="采购数量" prop="caigoushu" width="100" sortable> </el-table-column>
      <el-table-column label="归属库房" prop="cangku_name" width="100" sortable> </el-table-column>
      <el-table-column label="采购时间" prop="date" width="100" sortable> </el-table-column>
      <el-table-column label="采购说明" prop="remark"> </el-table-column>
      <el-table-column align="center" label="采购单据" width="120">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="handlePhotos(scope.row)">采购单据</el-button>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.caiwu_shenhe ? 'success' : 'warning'">{{ scope.row.caiwu_shenhe ? '已审核' : '未审核' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="添加" prop="added" width="150"></el-table-column>
      <el-table-column label="管理" width="100">
        <template slot-scope="scope">
          <el-button type="warning" icon="el-icon-delete" size="mini" @click="deleteTableRow(scope.$index, scope.row)" v-if="canDelete(scope.row)"></el-button>
          <el-button type="danger" size="mini" @click="handleCaiwu_shenhe(scope.row)" v-if="!scope.row.caiwu_shenhe && userType.code === '7'"
            >财务审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :page-size="pagesize"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  name: 'purTable',
  props: {
    //接收的参数和参数类型
    dataList: Array,
    config: Object,
    userType: Object
  },
  data() {
    return {
      pagesize: 20,

      TableHeaderRowStyle() {
        return 'height:35px'
      },
      TableHeaderCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
      },
      TableRowStyle() {
        return 'height:35px'
      },
      TableCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
      }
    }
  },
  computed: {
    canDelete() {
      return function(row) {
        // if (this.userType.id === 1) return true
        // else return false
        // let temp = true
        // if (temp) return true
        if (this.userType.caigou_del) {
          if (this.userType.code === '1') return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },
    handleCaiwu_shenhe(row) {
      this.$emit('Caiwu_shenhe', row)
    },
    handleWuzi_shenhe(row) {
      this.$emit('Wuzi_shenhe', row)
    },
    deleteTableRow(index, row) {
      this.$confirm('此操作将删除该采购信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/deleteCaigouxinxi/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
            this.dataList.splice(index, 1)
          } else {
            this.$message({
              message: '删除失败，此信息不存在或已被删除'
            })
          }
        })
      })
    },
    //显示图片
    handlePhotos(row) {
      this.$emit('showPhotos', row)
    }
  }
}
</script>

<style scoped></style>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
