<template>
  <div class="manage" style="height: 100%">
    <el-dialog width="700px" :visible.sync="isShow" title="添加新的采购信息">
      <caigou-form
        :form="caigouForm"
        ref="purForm"
        @submitForm="submitForm"
        @resetForm="resetForm"
      ></caigou-form>
    </el-dialog>
    <!-- 采购显示照片 -->
    <el-dialog
      width="1000px"
      :visible.sync="caigouPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in caigouphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="caigouPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-container style="height: 100%">
      <el-header>
        <div class="left">
          <el-button type="primary" @click="isShow = true">
            添加新的采购信息
          </el-button>
          <el-button type="primary"> 导出数据 </el-button>
        </div>
        <div class="right">
          <el-input
            placeholder="仓库 | 材料 | 材料规格"
            v-model="keyword"
            @keyup.enter.native="getTableData()"
          >
            <el-button slot="append" @click="getTableData()"> 查询 </el-button>
          </el-input>
        </div>
      </el-header>
      <el-main style="height: 100%">
        <caigou-table
          :dataList="tableData"
          :config="config"
          style="height: 100%"
          :userType="usertype"
          @Wuzi_shenhe="Wuzi_shenhe"
          @Caiwu_shenhe="Caiwu_shenhe"
          @changePage="getTableData"
          @showPhotos="showPhotosinTable"
        ></caigou-table>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CaigouForm from './caigouForm'
import CaigouTable from './caigouTable'
import utils from '../../../assets/js/utils'
export default {
  components: {
    CaigouForm,
    CaigouTable
  },
  data() {
    return {
      isShow: false,
      keyword: '',
      usertype: '',
      user_realname: '',
      tableData: [],
      company: utils.getCompanyCookie(),
      caigouForm: {},
      caigouPhotos_isShow: false,
      caigouphotosurls: [],
      config: {
        page: 1,
        total: 0,
        loading: false
      }
    }
  },
  created() {
    let user = utils.getUserCookie()
    this.usertype = user.user_type
    this.user_realname = user.name
    console.log('usertype:', this.usertype)
    console.log('user_realname:', this.user_realname)
    this.config.loading = true
    this.getTableData()
  },
  methods: {
    getTableData() {
      this.config.loading = true
      const api = '/getCaigouxinxis/'
      this.$axios
        .get(api, {
          params: {
            cangku_code: this.company, //当前用户所属分公司编号code
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            this.config.total = res.data.totalcount
            this.tableData = res.data.data.map(item => {
              item.cailiao_cailiaoguige =
                item.cailiao_name + item.cailiaoguige_name
              item.added =
                item.added_person +
                '\n' +
                utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            console.log('没有采购信息数据')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
      this.config.loading = false
    },

    //提交表单
    submitForm(formData) {
      this.caigouForm = formData
      console.log('caigouForm........:', this.caigouForm)
      this.caigouForm.added_person = this.user_realname

      this.$axios.post('/addCaigouxinxi/', this.caigouForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '增加采购信息成功!',
            duration: 2000
          })
          this.resetForm()
          this.getTableData()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetForm() {
      this.isShow = false
      this.caigouForm = {}
    },
    Caiwu_shenhe(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/caigouCaiwushenhe/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '审核成功！'
            })
            this.getTableData()
          } else {
            this.$message({
              message: '审核失败'
            })
          }
        })
      })
    },
    Wuzi_shenhe(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/caigouWuzishenhe/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '审核成功！'
            })
            this.getTableData()
          } else {
            this.$message({
              message: '审核失败'
            })
          }
        })
      })
    },
    //在table中显示出库图片
    showPhotosinTable(row) {
      this.caigouphotosurls = []
      let photos = row.photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地

        this.caigouphotosurls.push(url)
      }
      console.log(this.caigouphotosurls)
      this.caigouPhotos_isShow = true
    }
  }
}
</script>

<style scoped>
.inline {
  display: inline;
}
.el-main {
  padding-top: 0;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
