import { render, staticRenderFns } from "./caigouMain.vue?vue&type=template&id=5c8133bf&scoped=true&"
import script from "./caigouMain.vue?vue&type=script&lang=js&"
export * from "./caigouMain.vue?vue&type=script&lang=js&"
import style0 from "./caigouMain.vue?vue&type=style&index=0&id=5c8133bf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c8133bf",
  null
  
)

export default component.exports